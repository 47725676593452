import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import '../assets/Modal.scss';

const FeatureAnnouncement = (props) => {
  const { handleClose, feature, message } = props;

  const saveViewedFeatureAnnouncement = () => {
    localStorage[feature] = true;
  }
  return (
    <>
      <FontAwesomeIcon
        className='modal__close'
        icon={faTimesCircle} onClick={() => { handleClose(); saveViewedFeatureAnnouncement(); }}
      />
      <div className="feature-announcement">
        <h2 className="feature-announcement__title">New Admin Feature Available!</h2>
        <p className="feature-announcement__message">{message}</p>
      </div>
    </>
  )
}

export default FeatureAnnouncement;
