import React, {Component} from 'react';

import {Redirect, Link} from 'react-router-dom';
import Header from '../Header/Header.js';
import './Terms.scss';

class Terms extends Component {
    render() {
        let {role} = this.props;
        if(typeof(role) != "undefined") {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <React.Fragment>
            <Header userRole={role}/>
            <div className="container terms">
                <p className="terms__text">
                    We ask that no photos of Beckett to be posted on any social media platform. We thank you for your agreement and understanding. 
                </p>
                <Link to="invite"><button className="login__submit">I Agree</button></Link>
            </div>
            </React.Fragment>

        )
    }
}

export default Terms;