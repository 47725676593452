import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUser, faPencilAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Modal from '../Modal/Modal';
import FeatureAnnouncement from '../Modal/Content/FeatureAnnouncement';

class AdminMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      featureModalMessage: 'You can now rotate images! Find it in the edit post area.',
      featureModalRotateKey: 'admin_feature_rotate_image',
      showModal: true
    };
  }
  closeModal = () => {
    this.setState({ showModal: false });
  }
  render() {
    const { featureModalMessage, featureModalRotateKey } = this.state;
    if (!localStorage.getItem('admin_feature_rotate_image')) {
      return (
        <Modal>
          <FeatureAnnouncement
            handleClose={this.closeModal}
            message={featureModalMessage}
            feature={featureModalRotateKey} />
        </Modal>
      );
    }
    return (
        <div className="container admin-menu">
            <div className="admin-menu__link-cont">
                <Link to="/admin/add-post" className="admin-menu__link">Add Post <FontAwesomeIcon className="admin-menu__icon" icon={faPlus} /></Link>
                <Link to="/admin/edit-post/1" className="admin-menu__link">Edit Post <FontAwesomeIcon className="admin-menu__icon" icon={faPencilAlt} /></Link>
                <Link to="/admin/invites" className="admin-menu__link">Approve Invites <FontAwesomeIcon className="admin-menu__icon" icon={faEnvelope} /></Link>
                <Link to="/admin/users" className="admin-menu__link">Users <FontAwesomeIcon className="admin-menu__icon" icon={faUser} /></Link>
            </div>
        </div>
    )
    }
}

export default AdminMenu;