import React, { Component } from 'react';
import Moment from 'moment';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Comments from '../Comments/Comments.js';
import NewComment from '../Comments/NewComment.js';
import './Post.scss';

class Post extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newComments: 0
        }
    }

    formatContent = (content) => {
        try {
          content = content.replaceAll("\n", "<br>");
          return decodeURIComponent(content.replace(/\+/g, '%20'));
        } catch (error) {
          return content;
        }
    }

    formatDate = (date) => {
        let momentDate = Moment(date);
        let momentDateInAWeek = Moment(new Date());
        momentDateInAWeek = momentDateInAWeek.subtract(7, "days");
        if(momentDate > momentDateInAWeek) {
            momentDate = momentDate.subtract(7,'hours').fromNow();
        } else {
            momentDate = momentDate.format("MM.DD.YYYY");
        }
        return "Posted " + momentDate;
    }

    updateComment = (comment) => {
        this.setState({
            newComments: this.state.newComments + 1
        })
    }

    buildPostCarousel = (urls, individualPost) => {
      let {handleImageLoaded} = this.props;
      if(individualPost) {
        return (
          <Carousel showThumbs={false}>
            {urls.map(url => {
              return (
                <div className="post__flex-cont" key={url}>
                  <img className="post__image" src={url} alt="" onLoad={handleImageLoaded}/>
                </div>
              )
            })}
          </Carousel>
        )
      }

      return (
        <Carousel showThumbs={false}>
          {urls.map(url => {
            return (
              <div className="post__flex-cont" key={url}>
                <img className="post__image" src={url} alt="" onLoad={handleImageLoaded}/>
              </div>
            )
          })}
        </Carousel>
      )
    }

    buildSingleImage = (url, postId, individualPost) => {
      if(individualPost) {
        return (<img className="post__image" src={url} alt="" onLoad={this.props.handleImageLoaded} />)
      }
      return (
        <img key={url} className="post__image" src={url} alt="" onLoad={this.props.handleImageLoaded}/>
      )
    }

    parseImageUrls = (urlStrings) => {
      if(urlStrings) {
        return urlStrings.split(",");
      }
      return [];
    }

    render() {
      let {
        post_content, 
        post_image_url, 
        date_time_added, 
        post_id, 
        postClass, 
        individualPost
      } = this.props;

      if(post_image_url === null) {
        return null;
      }
      post_image_url = this.parseImageUrls(post_image_url, individualPost);
        return (
            <div className={postClass} >
                {post_image_url.length > 1
                ? this.buildPostCarousel(post_image_url, individualPost):
                this.buildSingleImage(post_image_url[0], post_id, individualPost)}
                
                <div className="post__content-cont">
                    <p className="post__date">{this.formatDate(date_time_added)}</p>
                    <p className="post__content" dangerouslySetInnerHTML={{ __html: this.formatContent(post_content)}}></p>
                    <Comments post_id={post_id} newComments={this.state.newComments} showAllComments={this.props.individualPost}/>
                    <hr />
                    <NewComment post_id={post_id} updateComment={this.updateComment} />
                </div>
            </div>
        )
    }
}

export default Post;