import React, {Component} from 'react';

import Loading from '../Loading/Loading.js';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import {createPostV2} from '../../Utilities/PostUtilities.js';

import "./AddPost.scss";

class AddPost extends Component {

    constructor(props) {
        super(props);
         this.state = {
             caption: "",
             img: null,
             file: null,
             name: null,
             type: null,
             loading: false,
             success: false,
             failed: false,
             isPrivate: false,
             imgArray: [],
             files: null
         }
    }

    handleFileOnChange = (evt) => {
      let imgArray = Object.assign([], this.state.imgArray);
      let imgData = Object.assign([], this.state.imgData);
      let files = evt.target.files;
      for(let i = 0, a = evt.target.files,c = a.length;i<c;i++) {
        let fr = new FileReader();
        let that = this;

        let name = evt.target.files[i].name.split(' ').join('');;
        let type = evt.target.files[i].type;
        fr.onload = function() { 
          let img = new Image();
          let result = this.result;

          img.onload = function() {
            imgArray.push({img: result, file: files[i]})
            imgData.push({width: img.width, height: img.height, name, type, order: imgData.length});
            that.setState({
              imgArray,
              imgData,
              files: Object.assign([], files).reverse()
            })
          };

          img.src = this.result;
        };

        fr.readAsDataURL(evt.target.files[i]);
      }
    }

    handleTextAreaChange = (evt) => {
        this.setState({
            caption: evt.target.value
        })
    }

    handleSubmitClick = (evt) => {
        this.setState({
            loading: true
        })
        evt.preventDefault();
        Promise.resolve(createPostV2(this.state, this.reportUploadProgress))
            .then(response => {                
                let success = response.data;
                if(typeof(success) !== "undefined") {
                    this.setState({
                        success: true
                    })
                } else {
                    this.setState({
                        failed: true
                    })
                }
                this.setState({
                    loading: false
                })
            })
    }

    buildImagePreviews = (imgArray) => {
      return imgArray.map(img => {
        return <img key={img.file.size} className="add-post__target" alt="" src={img.img}/>
      })
    }

    reportUploadProgress = (progressEvent) => {
        this.setState({
            uploadProgress: progressEvent
        })
    }

    resetPage = () => {
        this.setState({
          caption: "",
          img: null,
          file: null,
          name: null,
          type: null,
          loading: false,
          success: false,
          failed: false,
          isPrivate: false,
          imgArray: [],
          files: null
        })
    }

    render() {        
        let {loading, img, caption, success, failed, uploadProgress, imgArray} = this.state;

        if(loading) {
            return <Loading uploadProgress={uploadProgress}/>;
        }
        if(success) {
            return (
                <div className="container add-post">
                    <div>
                        <p className="add-post__success">Post was submitted successfully!</p>
                        <p className="add-post__success-message">Your post is being processed and will be available shortly. If there is a problem you will recieve an email with details.</p>
                        <p className="add-post__submit" onClick={this.resetPage}>Add Another Post</p>
                    </div>
                </div>
            )
        }
        if(failed) {
            return (
                <div className="container add-post">
                    <div>
                        <p className="add-post__success">Oops. Something went wrong.</p>
                        <p className="add-post__submit" onClick={this.resetPage}>Try Again</p>
                    </div>
                </div>
            )
        }
        return (
            <div className="container add-post">
                <Link to="/admin" ><p className="feed__go-back add-post__go-back"><FontAwesomeIcon icon={faChevronLeft} /> Back</p></Link>
                <p className="login__header add-post__header">Add Post</p>
                <p className="login__header add-post__subheader">To create a carousel post, select multiple images in the choose image popup. You can also select one image, then select "Choose An Image" again and select another. The order you see on this page will reflect the order of the carousel.</p>
                <label className="add-post__input-label">
                    {img ? "Choose Another Image" : "Choose An Image"}
                    <input type="file" accept="image/*" multiple className="add-post__input" onChange={this.handleFileOnChange}/>
                </label>
                {imgArray.length > 0 ? 
                    this.buildImagePreviews(imgArray)
                    :
                    null
                }
                <textarea className="add-post__caption" placeholder="Caption" rows="6" value={caption} onChange={this.handleTextAreaChange}></textarea>
                {imgArray.length > 0 && caption.length > 0 && <button className="add-post__submit" onClick={this.handleSubmitClick}>Submit Image</button>}
            </div>
        )
    }
}

export default AddPost;